@import './_all_vars.sass'

:root
  --bamboo-file-manager-description-text: #{$recall-bamboo-file-manager-description-text}

div:global(.pageThemeDark)
  --bamboo-file-manager-description-text: #{$recall-bamboo-file-manager-description-text-darkmode}

.uploadSubtitle
    color: var(--bamboo-file-manager-description-text) !important
  
.modal .fileupload
  width: 100% !important