@import './_all_vars.sass'
.input
  label
    div
      > textarea
        resize: none

.label
  font-family: Arial, Helvetica, sans-serif
  font-weight: 700
  font-size: 14px

.form
  label
    margin-right: 8px

  .checkboxInfo
    color: $recall-primary !important