@import '../_all_vars'

:root
  --cra-property-text-color: #{$primary--text--colour}
  --cra-pill-box-text-color: #{$secondary--text--colour}
  --cra-button-background: #{$recall-cra-button-background}
  --cra-property-no-data-text-color: #{$recall-cra-label-text-color}

div:global(.pageThemeDark)
  --cra-property-text-color: #{$secondary--text--colour}
  --cra-pill-box-text-color: #{$primary--text--colour}
  --cra-button-background: #{$recall-cra-button-background-darkmode}
  --cra-property-no-data-text-color: #{$recall-cra-label-text-color-darkmode}

.metadataRow
  padding-top: 24px
  padding-left: 24px
  padding-right: 24px

  .metadataFieldRow
    padding-top: 8px

    .metadataFieldlabel
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.1px
      text-align: left
      display: inline-block

    .metadataPillsBox
      display: flex
      flex-wrap: wrap
      gap: 8px
      color: var(--cra-pill-box-text-color)

    .metadataPill
      border-radius: 40px

      span
        font-size: 12px

      &.item
        background-color: var(--cra-button-background)
    
    .comboBox
      width: 100%
      &.loading
        i:global(.custom-icon)
          animation: spin-animation 1s infinite
          display: inline-block
          color: var(--cra-property-text-color) !important
      
      .internal
        div:global(.input-field)
          border: 1px solid var(--cra-property-text-color) !important
          border-radius: 3px

          i:global(.custom-icon)
            color: var(--cra-property-text-color) !important
            
          i:global(.dropdown-icon)
            color: var(--cra-property-text-color) !important

          &:focus, &:active, &:hover
            &::placeholder
              color: var(--cra-property-text-color) !important
            input
              color: var(--cra-property-text-color) !important
              &::placeholder
                color: var(--cra-property-text-color) !important

        div:global(.is-open)
          input
            color: var(--cra-property-text-color) !important
            &::placeholder
              color: var(--cra-property-text-color) !important

        li
          &:hover
            background: var(--cra-button-background) !important
        div:global(.options-wrapper)
          border: 1px solid var(--cra-property-text-color) !important

      
      input
        color: var(--cra-property-text-color) !important

    .errorMessage
      font-size: 12px
      line-height: 16px
      margin-left: auto
      color: red
      margin-top: 8px
      width: 100%


    @keyframes spin-animation
      0%
        transform: rotate(0deg)
      100%
        transform: rotate(360deg)