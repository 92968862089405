@import './_all_vars.sass'

:root
  --cra-modal-nomic-text-color: #{$recall-cra-text-modal}
  --cra-modal-nomic-text-color-secondary: #{$recall-cra-text-modal-secondary-darkmode}
  --cra-button-background: #{$recall-cra-button-background}
  --cra-property-no-data-text-color: #{$recall-cra-label-text-color}
  --cra-property-anchor-text-color: #{$recall-cra-button-color-darkmode}
  --cra-notify-text-color: #{$recall-cra-notify-link-color}
  --cra-manage-modal-icon-color: #{$secondary--text--colour}

div:global(.pageThemeDark)
  --cra-modal-nomic-text-color: #{$secondary--text--colour}
  --cra-modal-nomic-text-color-secondary: #{$recall-cra-text-modal-secondary-darkmode}
  --cra-button-background: #{$recall-cra-button-background-darkmode}
  --cra-property-no-data-text-color: #{$recall-cra-label-text-color-darkmode}
  --cra-property-anchor-text-color: #{$recall-cra-button-color}
  --cra-notify-text-color: #{$recall-cra-notify-link-color-darkmode}
  --cra-manage-modal-icon-color: #{$primary--text--colour}

.nomicOrgModal
  div:global(.modal)
    overflow: visible
    overflow-x: clip
  div:global(.modal-content)
    overflow: unset

.contentWrapperNomicModal
  h2
    font-size: 32px
    text-align: left
    color: var(--cra-modal-nomic-text-color)
  
  input, textarea
    background-color: transparent !important

  .propertyDescriptionManageAccess
    color: var(--cra-modal-nomic-text-color)
    font-weight: 400

  .propertyDescriptionNomicModal
    color: var(--cra-modal-nomic-text-color-secondary)
    font-weight: 400

    a
      color: var(--cra-property-anchor-text-color)

  .typeDropdownHolder
    flex-direction: column

    .dropdownError
      margin-top: 8px
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 18px
      color: red


  .notificationDescription
    color: var(--cra-modal-nomic-text-color)
    font-weight: 400
    
    a:global(.notify-url)
      color: var(--cra-notify-text-color)

  .typeWrapper
    display: flex
    width: 100%

    .checkboxWrapper
      width: 25%
      font-size: 14px
      padding-left: 20px

      label
        margin-top: 18px
      
      b
        font-weight: 500
        
    .dropdownWrapper
      width: 75%
      .type
        margin-top: 10px

  .infoRowNomicModal span
    display: flex
    flex-wrap: nowrap
    align-items: center
    font-size: 16px
    font-weight: 400
    color: var(--cra-modal-nomic-text-color-secondary)

    i
      color: var(--cra-property-no-data-text-color)

    span
      margin-left: 8px
      color: var(--cra-property-no-data-text-color)
      font-size: 16px
      font-weight: 400

  .instructions
    display: flex
    align-items: center
    gap: 16px
    justify-content: center

    i
      color: var(--cra-property-no-data-text-color)
  
  .revokeBtn
    i
      color: var(--cra-modal-nomic-text-color) !important
    &:hover
      background: var(--cra-button-background) !important
      i
        color: var(--cra-manage-modal-icon-color) !important
  
  .dropdownCustomItem
    color: #1692BF


      

