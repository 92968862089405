@import './_all_vars.sass'

.pageHeader
  display: flex
  flex: 0
  align-items: center

  .pageExpand
    flex: 0

    .headerButton
      border: none

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        background-color: #ddd !important
        border: none !important
        color: $recall-main-dark

  .pageTitle
    flex: 1
    font-family: inherit
    font-size: 40px
    text-align: center
    padding: 16px 0

