@import './_all_vars.sass'

:root
  --file-preview-background-colour: #{$recall-file-preview-background}
  --file-preview-text-colour: #{$recall-text-menu}

div:global(.pageThemeDark)
  --file-preview-background-colour: #{$recall-file-preview-background-darkmode}
  --file-preview-text-colour: #{$secondary--text--colour}

.fileItem
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  gap: 6px

  .filePreview
    height: 100px
    width: 180px
    object-fit: cover
    border-top-right-radius: 10px
    border-top-left-radius: 10px
    cursor: pointer
    box-sizing: border
    overflow: hidden

  .imageInfo
    background: var(--file-preview-background-colour)
    border-radius: 10px
    border: 1px solid var(--file-preview-background-colour)

    .imageData
      font-size: 12px
      height: 35px
      padding: 0 10px
      width: 180px
      color: var(--file-preview-text-colour)

      .name
        max-width: 180px
        text-wrap: nowrap
        text-overflow: ellipsis
        overflow: hidden


  .fileDeletedWrapper
    align-items: baseline
    display: flex

    .fileNameTooltipLabel
      word-wrap: break-word

    .fileDeletedName
      text-wrap: nowrap
      font-size: 12px
      max-width: 180px
      overflow: hidden
      text-overflow: ellipsis

    .fileDeletedIcon
      margin-left: 8px

      span:global(.tooltip-popup)
        max-width: 400px !important

  .imagePreviewButton
    position: absolute
    top: 8px
    right: 8px
    color: $recall-text-menu !important
    background-color: $recall-primary-white !important
    border: none !important

    &:hover
      background-color: $recall-menu-background !important
      border: 1px solid $recall-text-menu !important 

    &:hover:enabled:not(.is-loading)
      border-color: transparent
      color: white
