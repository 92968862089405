.fileBrowserLayout
    .mainContent
        display: flex
        flex-direction: column

        div:global(.table-main-wrapper)
            max-height: 300px
            overflow-y: scroll

            .checkBoxCell
                width: 10%

            .iconCell
                display: flex
                gap: 8px

                .fileIcon
                    width: 20px

.breadcrumbs
    margin: 8px 0

.buttonContainer
    display: flex
    gap: 8px
    margin: 8px 0

    .actionBtn
        border-radius: 24px
