@import './_all_vars.sass'

.wrapper
  display: flex
  flex-direction: column
  position: fixed
  z-index: 10000
  padding: 16px
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: $recall--modal--background

  .content
    flex: 0
    margin: auto
    max-width: 80%
    color: $secondary--text--colour--light

    pre
      text-wrap: pretty

    .image
      width: 100%

    .name
      margin: auto
      display: block
      max-width: 80%
      text-align: center
      padding: 10px
      overflow-wrap: break-word

  .closeButton
    position: fixed
    top: 48px
    right: 48px
