@import './_all_vars.sass'
  
:root
  --edit-history-background: #{$recall-menu-background}
  --edit-history-separator: #{$recall-separator}

div:global(.pageThemeDark)
  --edit-history-background: #{$recall-menu-background-darkmode}
  --edit-history-separator: #{$recall-separator-darkmode}

.navigation
  display: flex
  align-items: center
  gap: 12px

.content
  padding: 8px
  border: 1px solid var(--edit-history-separator)
  margin: 16px 0 0
  background-color: var(--edit-history-background)