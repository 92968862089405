@import './_all_vars.sass'

:root
  --bamboo-chat: #{$recall-bamboo-chat}
  --bamboo-header-background: #{$recall-bamboo-header-background}
  --bamboo-header-title-text: #{$recall-heading}
  --bamboo-file-upload-text-colour: #{$primary--text--colour}
  --header-manage-file-button-background: #{$recall-bamboo-header-manage-file-button-background}
  --header-manage-file-button-color: #{$recall-bamboo-header-manage-file-button-color}
  --header-manage-file-right-side-background: #{$recall-bamboo-header-right-side-background}
  --header-header-selected-file: #{$recall-bamboo-header-selected-file}
  --header-header-selected-filename: #{$recall-bamboo-header-selected-filename}
  --header-header-selected-filepage: #{$recall-bamboo-header-selected-filepage}
  --bamboo-button-background: #{$recall-bamboo-button-background}
  --bamboo-button-border: #{$recall-bamboo-button-border}
  --bamboo-button-background-hover: #{$recall-bamboo-button-background-hover}
  --bamboo-button-border-hover: #{$recall-bamboo-button-background-hover}
  --bamboo-button-background-pressed: #{$recall-bamboo-button-background-pressed}
  --bamboo-button-background-disabled: #{$recall-bamboo-button-background-disabled}
  --bamboo-button-text-disabled: #{$recall-bamboo-button-text-disabled}
  --bamboo-button-background-pressed: #{$recall-bamboo-button-background-pressed}
  --bamboo-button-border-focus: #{$recall-button-border-focus}
  --bamboo-button-text-color: #{$recall-bamboo-button-color}
  --bamboo-entity-sidebar-background: #{$recall-bamboo-entity-sidebar-background}
  --bamboo-entity-sidebar-title: #{$recall-bamboo-entity-sidebar-title}
  --bamboo-home-text-color: #{$recall-bamboo-home-text-color}
  --bamboo-entity-description-color: #{$recall-bamboo-entity-description-color}
  --bamboo-entity-selected-background: #{$recall-bamboo-entity-selected-background}
  --bamboo-table-cell-border-bottom: #{$recall-bamboo-table-cell-border-bottom}

div:global(.pageThemeDark)
  --bamboo-chat: #{$recall-bamboo-chat-darkmode}
  --bamboo-header-background: #{$recall-bamboo-header-background-darkmode}
  --bamboo-header-title-text: #{$secondary--text--colour}
  --bamboo-file-upload-text-colour: #{$secondary--text--colour}
  --header-manage-file-button-background: #{$recall-bamboo-header-manage-file-button-background-darkmode}
  --header-manage-file-button-color: #{$recall-bamboo-header-manage-file-button-color-darkmode}
  --header-manage-file-right-side-background: #{$recall-bamboo-header-right-side-background-darkmode}
  --bamboo-button-background: #{$recall-bamboo-button-background-darkmode}
  --bamboo-button-border: #{$recall-bamboo-button-border-darkmode}
  --bamboo-button-background-hover: #{$recall-bamboo-button-background-hover-darkmode}
  --bamboo-button-border-hover: #{$recall-bamboo-button-background-hover-darkmode}
  --bamboo-button-background-pressed: #{$recall-bamboo-button-background-pressed-darkmode}
  --bamboo-button-background-disabled: #{$recall-bamboo-button-background-disabled-darkmode}
  --bamboo-button-text-disabled: #{$recall-bamboo-button-text-disabled-darkmode}
  --bamboo-button-border-focus: #{$recall-button-border-focus-darkmode}
  --bamboo-button-text-color: #{$recall-bamboo-button-color-darkmode}
  --bamboo-entity-sidebar-background: #{$recall-bamboo-entity-sidebar-background-darkmode}
  --header-header-selected-file: #{$recall-bamboo-header-selected-file-darkmode}
  --header-header-selected-filename: #{$recall-bamboo-header-selected-filename-darkmode}
  --header-header-selected-filepage: #{$recall-bamboo-header-selected-filepage-darkmode}
  --bamboo-entity-sidebar-title: #{$recall-bamboo-entity-sidebar-title-darkmode}
  --bamboo-home-text-color: #{$recall-bamboo-home-text-color-darkmode}
  --bamboo-entity-description-color: #{$recall-bamboo-entity-description-color-darkmode}
  --bamboo-entity-selected-background: #{$recall-bamboo-entity-selected-background-darkmode}
  --bamboo-table-cell-border-bottom: #{$recall-bamboo-table-cell-border-bottom-darkmode}

.chatWrapper
  margin: 0 !important
  padding: 0 !important

  div:first-child
    margin-top: 0
    padding-right: 0
    margin-bottom: 0

    .chatHeader
      background-color: var(--bamboo-header-background)
      padding: 24px
      display: flex
      width: 100%
      height: 100px

      .leftSide
        display: flex
        align-items: center
        flex: 1

        .pdfThumbnail
          cursor: pointer
          position: relative

          > div
            position: relative !important
            overflow: hidden

        .selectedFile
          margin-left: 24px
          font-size: 14px
          display: flex
          flex-direction: column

          .selectedFileText
            color: var(--header-header-selected-file)
            margin-bottom: 4px

            .fileName
              text-decoration: underline
              cursor: pointer

              &.removed
                cursor: auto

            .selectedFileName
              color: var(--header-header-selected-filename)

              .fileDeletedIcon
                margin-left: 5px

            .filePage
              margin-left: 16px
              font-weight: bold
              color: var(--header-header-selected-filepage)

        .manageFile
          padding: 0 24px

          .manageFileButton
            display: flex
            align-items: center
            justify-content: center
            background: var(--header-manage-file-button-background) !important
            color: var(--header-manage-file-button-color) !important
            border: 1px solid var(--header-manage-file-button-background) !important
            padding: 12px 24px

            > span
              line-height: normal
              &:hover
                border: none !important

                &:focus:enabled, &:active:enabled
                  background: var(--header-manage-file-button-background) !important
                  color: var(--header-manage-file-button-color) !important
                  border: 1px solid var(--bamboo-button-border-hover) !important

      .rightSide
        display: flex
        align-items: center
        margin-right: 24px
        padding: 8px
        justify-content: space-between
        background-color: var(--header-manage-file-right-side-background)
        border: 1px solid var(--header-manage-file-right-side-background)
        border-radius: 32px

        .exportButton
          margin-left: 8px

    .chatContent
      display: flex
      flex: 1
      height: 100%
      width: 100%

      .entitySideBar
        width: 261px
        background-color: var(--bamboo-entity-sidebar-background)
        padding: 0 0 0 30px

        .createEntityButton
          span
            text-decoration: underline
            p
              color: var(--bamboo-entity-sidebar-title)
              font-size: 24px
              font-weight: 400

        .entityList
          padding: 0 16px 0 0
          position: relative
          cursor: pointer

          .entityCard
            cursor: pointer

            &.selected
              background-color: var(--bamboo-entity-selected-background)
              border-left: 2px solid var(--bamboo-chat) !important
              padding: 0 16px 16px 30px
              margin-left: -30px
              margin-right: -15px

            .titleRow
              display: flex
              align-items: baseline
              justify-content: space-between

              span:global(.tooltip-wrapper)
                width: 80%

            .entityName
              font-weight: 400
              font-size: 14px
              margin: 0
              padding: 16px 0
              overflow: hidden
              text-overflow: ellipsis
              white-space: nowrap

            .description
              position: relative
              font-size: 12px
              font-weight: 400
              color: var(--bamboo-entity-description-color)
              display: -webkit-box
              text-overflow: ellipsis
              overflow: hidden
              -webkit-line-clamp: 5
              line-clamp: 5
              -webkit-box-orient: vertical
              width: 215px

            .wordWrap
              display: flex
              width: 100%
              word-wrap: break-word
              word-break: break-all

            .entityHeader
              display: flex
              flex-direction: row
              align-items: center
              justify-content: space-between

            .tooltipDescription
              span:global(.tooltip-popup)
                span
                  position: relative
                  top: 25px

      .propertyWrapper
        display: flex
        padding: 16px
        height: 100%
        width: 100%
        flex-direction: column
        overflow: hidden

        .actionButtons
          button
            span
              text-decoration: underline

              .clearAllButton
                i
                  color: inherit

        .propertyContent
          display: flex
          flex: 1
          align-items: center
          flex-direction: column
          justify-content: center

          p
            font-size: 20px
            color: var(--bamboo-home-text-color)

        .propertyTable
          overflow-y: hidden
          min-height: 100px
          color: var(--bamboo-header-title-text)

          td:global(.table-cell)
            div
              align-items: center

            div:global(.overflow-menu-wrapper)
              margin-left: 40px

            span:global(.tooltip-popup)
              font-weight: normal

              span
                word-wrap: break-word

          .cellWrapper
            max-width: 200px
            border-bottom: 1px solid var(--bamboo-table-cell-border-bottom) !important

            .cellContent
              width: 100%
              text-overflow: ellipsis
              overflow: hidden
              white-space: nowrap
              display: inline-block

.headerTitle
  margin-bottom: 0px

.experimental
  display: flex
  justify-content: center
  font-size: 11px

.headerText
  text-align: center
  p
    color: var(--bamboo-header-title-text)
    margin: 8px 0px
    font-weight: 400

.uploadArea
  h3
    margin: 4px 0px
    color: var(--bamboo-header-title-text)

    .uploadSubtitle
      color: var(--bamboo-home-text-color)

.bambooFooter
  display: flex
  justify-content: flex-end
  align-items: center
  margin-top: 20px

  .nextButton
    display: flex
    justify-content: center
    align-items: center
    background: var(--bamboo-button-background) !important
    border: var(--bamboo-button-border) !important
    border-radius: 20px
    padding: 12px 24px
    color: var(--bamboo-button-text-color) !important

    &:hover
      background: var(--bamboo-button-background-hover) !important

    > span
      line-height: normal

      &:hover
        background: var(--bamboo-button-background-hover) !important

        &:focus
          background: var(--bamboo-button-background) !important
          border-color: var(--bamboo-button-border-focus) !important

        &:active
          background: var(--bamboo-button-background-pressed) !important

        &:disabled
          color: var(--bamboo-button-text-disabled) !important
          background: var(--bamboo-button-background-disabled) !important
          pointer-events: none !important

.dropzoneLabelContainer
  .dropzoneLabelTextContainer
    color: var(--bamboo-file-upload-text-colour)
    margin-bottom: 2px

    span
      cursor: pointer
      font-size: 1rem
      font-weight: 700
      text-decoration: underline
      color: var(--bamboo-button-background)

@media screen and (max-width: $breakpoint--mobile)
  .headerTitle
    margin: 36px 0 8px 0 !important
