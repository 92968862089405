
  @import './_all_vars.sass'


:root
  --bhp-btn-color: #{$recall-bhp-chat-color}
  --bhp-btn-background: #{$recall-bhp-chat}
  --rio-btn-color: #{$recall-bhp-chat-color}
  --rio-btn-background: #{$recall-rio-tinto-chat}
  --ausnet-btn-color: #{$recall-bhp-chat-color}
  --ausnet-btn-background: #{$recall-ausnet-chat}

  --standard-chat-btn-color-hover: #{$secondary--text--colour}
  --standard-chat-btn-background-hover: #{$recall-standard-chats-btn-background-hover}

div:global(.pageThemeDark)
  --bhp-btn-color: #{$recall-bhp-chat-color}
  --bhp-btn-background: #{$recall-bhp-chat}
  --rio-btn-color: #{$recall-bhp-chat-color}
  --rio-btn-background: #{$recall-rio-tinto-chat}
  --ausnet-btn-color: #{$recall-bhp-chat-color}
  --ausnet-btn-background: #{$recall-ausnet-chat}

  --standard-chat-btn-color-hover: #{$recall-primary-black}
  --standard-chat-btn-background-hover: #{$recall-standard-chats-btn-background-hover-darkmode}

.disabledInput
  line-height: 41px
  margin: 0 auto

.input
  flex: 1

  label
    div
      > textarea
        background-color: transparent !important
        border: none !important
        padding: 8px !important
        max-height: 250px !important
        min-height: 40px !important
        resize: none

.input:global(.field-wrapper)
  label
    line-height: 11px

    textarea
      font-family: inherit !important

.submitButton:global(.is-primary)
  border-radius: 20px

  &.chatGpt
    background-color: $recall-gpt-chat
    border-color: $recall-gpt-chat
    color: $recall-gpt-chat-color

    &:hover:enabled:not(.is-loading)
      background-color: mix($recall-gpt-chat, $recall-gpt-chat-hover) !important
      border-color: $recall-gpt-chat
      color: $recall-gpt-chat-color

    &:focus:enabled:not(.is-loading)
      border-color: $recall-gpt-chat-hover
      background-color: $recall-gpt-chat-hover !important

  &.chatRecall
    background-color: $recall-recall-chat
    border-color: $recall-recall-chat
    color: $recall-recall-chat-color

    &:hover:enabled:not(.is-loading)
      background-color: mix($recall-recall-chat, $recall-recall-chat-hover) !important
      border-color: $recall-recall-chat
      color: $recall-recall-chat-color

    &:focus:enabled:not(.is-loading)
      border-color: $recall-recall-chat-hover
      background-color: $recall-recall-chat-hover !important
  
  &.cra
    background-color: $recall-cra-chat
    border-color: $recall-cra-chat
    color: $recall-cra-chat-color

    &:hover:enabled:not(.is-loading)
      background-color: mix($recall-cra-chat, $recall-cra-chat-hover) !important
      border-color: $recall-cra-chat
      color: $recall-cra-chat-color

    &:focus:enabled:not(.is-loading)
      border-color: $recall-cra-chat-hover
      background-color: $recall-cra-chat-hover !important

  &.bhp
    background-color: var(--bhp-btn-background)
    border-color: var(--bhp-btn-background)
    color: var(--bhp-btn-color)

    &:hover:enabled:not(.is-loading)
      background-color: var(--standard-chat-btn-background-hover) !important
      border-color: var(--bhp-btn-background-hover)
      color: var(--standard-chat-btn-color-hover)

    &:focus:enabled:not(.is-loading)
      border-color: var(--standard-chat-btn-background-hover)
      background-color: $recall-bhp-chat !important
      color: var(--bhp-btn-color)

  &.rioTinto
    background-color: var(--rio-btn-background)
    border-color: var(--rio-btn-background)
    color: var(--rio-btn-color)

    &:hover:enabled:not(.is-loading)
      background-color: var(--standard-chat-btn-background-hover) !important
      border-color: var(--rio-btn-background-hover)
      color: var(--standard-chat-btn-color-hover)

    &:focus:enabled:not(.is-loading)
      border-color: var(--standard-chat-btn-background-hover)
      background-color: $recall-rio-tinto-chat !important
      color: var(--rio-btn-color)
  
  &.ausNet
    background-color: var(--ausnet-btn-background)
    border-color: var(--ausnet-btn-background)
    color: var(--ausnet-btn-color)

    &:hover:enabled:not(.is-loading)
      background-color: var(--standard-chat-btn-background-hover) !important
      border-color: var(--ausnet-btn-background-hover)
      color: var(--standard-chat-btn-color-hover)

    &:focus:enabled:not(.is-loading)
      border-color: var(--standard-chat-btn-background-hover)
      background-color: $recall-ausnet-chat !important
      color: var(--ausnet-btn-color)

  &.pmApp
    background-color: $recall-pm-chat
    border-color: $recall-pm-chat
    color: $recall-pm-chat-color

    &:hover:enabled:not(.is-loading)
      background-color: var(--standard-chat-btn-background-hover) !important
      border-color: var(--standard-chat-btn-background-hover)
      color: var(--standard-chat-btn-color-hover)

    &:focus:enabled:not(.is-loading)
      border-color: var(--standard-chat-btn-background-hover)
      background-color: $recall-pm-chat !important
      color: $recall-pm-chat-color

@media screen and (max-width: $breakpoint--tablet + 1)
  .input
    label
      div
        > textarea
          padding: 8px 40px 8px 12px
