@import './_all_vars.sass'

:root
    --history-chat-item-icon-text-colour: #{$recall-main-dark}
    --history-chat-item-icon-text-colour-hover: #{$recall-text-menu}
    --history-chat-separator: #{$recall-separator}
    --history-chat-info-icon: #{$recall-text-lighter}
    --history-chat-message-icon: #{$recall-main-light}
    --history-chat-pill-text: #{$recall-pill-info-text}

div:global(.pageThemeDark)
    --history-chat-item-icon-text-colour: #{$recall-green-300-darkmode}
    --history-chat-item-icon-text-colour-hover: #{$recall-green-200-darkmode}
    --history-chat-separator: #{$recall-separator-darkmode}
    --history-chat-info-icon: #{$recall-primary-text-colour-darkmode}
    --history-chat-message-icon: #{$recall-green-300-darkmode}
    --history-chat-pill-text: #{$recall-primary-text-colour-darkmode}

.message
  align-self: center

  p
    text-align: center

    i
      color: var(--history-chat-message-icon)

.searchFilter
  display: flex
  flex-direction: row
  flex-wrap: wrap
  gap: 16px
  margin-bottom: 16px

  .filters
    flex: 0
    display: flex
    min-width: 200px



.chatItem
  display: flex
  flex-direction: column
  border-bottom: 1px solid var(--history-chat-separator)
  margin: 16px 0
  padding: 16px 0
  gap: 16px

  .question
    display: flex 
    font-size: 22px
    font-weight: 100

    &:hover
      i
        color: var(--history-chat-item-icon-text-colour-hover)  

      .questionText
        color: var(--history-chat-item-icon-text-colour-hover)

    i
      align-self: center
      margin-right: 16px
      margin-top: 16px
      align-self: flex-start
      line-height: 1
      color: var(--history-chat-item-icon-text-colour)

    .questionText
      @include lato-regular
      font-size: 16px
      cursor: pointer
      color: var(--history-chat-item-icon-text-colour)
      text-wrap: pretty

  .infoPillHolder
    display: flex

    i
      margin-top: 8px
      margin-right: 16px
      color: var(--history-chat-info-icon)

    .info
      display: flex
      row-gap: 16px
      flex-wrap: wrap

      > span
        margin-right: 16px
        color: var(--history-chat-pill-text)

      span:global(.pill-wrapper)
        span
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          max-width: 700px

    span:global(.pill-wrapper)
      span
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        max-width: 700px

.pagination
  display: flex
  align-self: center
  margin: 1rem 0

@media screen and (max-width: $breakpoint--mobile)
  .searchFilter
    flex-direction: column

  .chatItem
    .info
      flex-direction: row
      flex-wrap: wrap

      i
        display: none
      > span
        align-self: flex-start
        margin: 0px 8px 6px 8px