@import '../_all_vars'

:root
    --bamboo-checkbox-checked-background: #{$recall-bamboo-checkbox-checked-background}
    --bamboo-checkbox-hover-background: #{$recall-bamboo-checkbox-hover-background}
    --bamboo-checkbox-icon-color: #{$recall-bamboo-checkbox-icon-color}

div:global(.pageThemeDark)
    --bamboo-checkbox-checked-background: #{$recall-bamboo-checkbox-checked-background-darkmode}
    --bamboo-checkbox-hover-background:#{$recall-bamboo-checkbox-checked-background-hover-darkmode}
    --bamboo-checkbox-icon-color: #{$recall-bamboo-checkbox-icon-color-darkmode}

.bamboo

    div:global(.checkbox)
        background-color: unset
        border: 1px solid var(--bamboo-form-input-border-color)

        &:hover
            background-color: unset
            border: 1px solid var(--bamboo-checkbox-hover-background) !important


    div:global(.checkbox.is-checked)
        background-color: var(--bamboo-checkbox-checked-background)

        i
            color: var(--bamboo-checkbox-icon-color)

        &:hover
            background-color: var(--bamboo-checkbox-hover-background) !important
