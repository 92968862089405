@import './_all_vars.sass'

:root
  --menu-background-colour: #{$recall-menu-background}
  --menu-item-background-colour-hover: #{$recall-text-menu-background-hover}
  --menu-text-colour: #{$recall-text-menu}
  --menu-item-active-colour: #{$recall-primary-black}
  --menu-item-active-logo-colour: #{$recall-main-light}
  --menu-item-icon-colour: #{$recall-primary-black}
  --menu-item-colour-primary: #{$recall-primary}
  --menu-item-version-colour: #{$recall-primary-black}
  --menu-logo-text-colour: #{$recall-text-menu}
  --menu-logo-colour1: #{$recall-primary}
  --menu-logo-colour2: #{$recall-menu-background}

div:global(.pageThemeDark)
  --menu-background-colour: #{$recall-menu-background-darkmode}
  --menu-item-background-colour-hover: #{$recall-menu-background-hover-darkmode}
  --menu-text-colour: #{$recall-primary-text-colour-darkmode}
  --menu-item-active-colour: #{$recall-primary-white}
  --menu-item-active-logo-colour: #{$recall-green-300-darkmode}
  --menu-item-icon-colour: #{$recall-primary-text-colour-darkmode}
  --menu-item-colour-primary: #{$recall-green-300-darkmode}
  --menu-item-version-colour: #{$recall-green-300-darkmode}
  --menu-logo-text-colour: #{$recall-primary-white}
  --menu-logo-colour1: #{$recall-primary-white}
  --menu-logo-colour2: #{$recall-menu-background-darkmode}

.themeSwitcherContainer
  display: flex
  justify-content: center
  align-items: center
  margin: 8px 0px
  flex-wrap: wrap
  >div
    margin: 4px 6px
  
  span
    font-weight: 400 !important

.menu
  background-color: var(--menu-background-colour)
  border-radius: 0
  margin: 0
  padding: 32px 0
  width: $recall-menu-width
  position: relative
  display: flex
  flex-direction: column
  z-index: 100
  transition: background-color 250ms linear

  :global(.recallLogoSvg)
    fill: var(--menu-logo-colour1)

  :global(.recallNameSvg)
    fill: var(--menu-logo-text-colour)

  :global(.recallLogoLetterSvg)
    fill: var(--menu-logo-colour2)

  :global(.recallLogoSvgSmall)
    fill: var(--menu-logo-colour1)

  :global(.recallNameSvgSmall)
    fill: var(--menu-logo-colour2)

  .recallLogo
    padding: 0
    padding-left: 32px
    margin-bottom: 16px
    width: $recall-menu-logo-width

  &.collapsed
    .recallLogo
      padding: 0 6px
      width: $recall-menu-logo-width-small
    .menuItem
      padding-left: 12px

  .menuItem
    position: relative
    flex: 0
    width: 100%
    color: var(--menu-text-colour)
    font-size: 16px
    padding: 16px 16px 16px 32px
    margin: 4px 0
    cursor: pointer
    font-family: inherit

    &.active
      color: var(--menu-item-active-colour)
      i
        color: var(--menu-item-active-logo-colour)

    img
      vertical-align: sub
      height: 20px
      padding-left: 4px

    i
      top: -2px
      color: var(--menu-item-icon-colour)
      margin-right: 6px

    &.disabled
      opacity: 0.5

    &:hover
      cursor: pointer
      background-color: var(--menu-item-background-colour-hover)

  .menuFooter
    flex: 1
    align-content: flex-end

    .menuFullscreen
      font-family: inherit
      font-size: 12px
      text-align: center
      margin: 12px 0px

    .menuLanguage
      font-family: inherit
      font-size: 12px
      text-align: center
      margin: 12px 0px
      display: flex
      width: 100%
      justify-content: center

    .menuVersion
      font-family: inherit
      font-size: 12px
      text-align: center
      margin: 12px
      color: var(--menu-item-version-colour)
      display: flex
      justify-content: center

    .terms
      font-family: inherit
      text-align: center
      margin: 12px

    a
      font-size: 12px
      color: var(--menu-item-colour-primary)

@media screen and (max-width: $breakpoint--tablet + 1)
  .menu
    border-radius: 0
    margin: 0
    width: $recall-menu-width-small

    .menuItem
      .appsMenuWrapper
        left: $recall-menu-width - 84px

@media screen and (max-width: $breakpoint--desktop-large + 1)
  .menu
    border-radius: 0
    margin: 0
