@import '../_all_vars.sass'

.charactersLeft
  display: flex
  width: 100%
  justify-content: flex-end
  font-size: 12px
  margin-top: 13px
  font-family: Arial, sans-serif
  font-style: normal
  font-weight: normal
