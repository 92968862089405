@import './_all_vars.sass'

:root
  --switcher-dropdown-background-colour: #{$recall-dropdown-background}
  --switcher-dropdown-button-colour: #{$recall-primary-black}
  --switcher-dropdown-active-text-colour: #{$recall-primary-white}
  --switcher-dropdown-active-background-colour: #{$recall-active-background-colour}
  --switcher-text-colour: #{$recall-primary-black}
  --switcher-background-colour: #{$recall-primary-white}

div:global(.pageThemeDark)
  --switcher-dropdown-background-colour: #{$recall-dropdown-background-darkmode}
  --switcher-dropdown-button-colour: #{$recall-primary-white}
  --switcher-dropdown-active-text-colour: #{$recall-primary-black}
  --switcher-dropdown-active-background-colour: #{$recall-dropdown-background}
  --switcher-text-colour: #{$recall-primary-white}
  --switcher-background-colour: #{$recall-primary-black-darkmode}

.languageSwitcherContainer
  position: relative
  background-color: transparent
  justify-content: center
  color: var(--language-switch-text-colour)
  width: 80px
  border: 0px

  .selectedlanguage
    display: flex
    border-radius: 5px
    cursor: pointer
    border-color: $recall-code-chat-disabled
    justify-content: center
    align-items: center

    .label
      padding: 10px
      border-top-left-radius: 5px
      border-bottom-left-radius: 5px
      color: var(--switcher-text-colour)
      display: flex
      align-items: center
      font-size: 16px

      img
        width: 24px
        margin: 0px 6px
      .icon
        position: relative
        top: 5px
        border-radius: 50%
        width: 12px
        height: 12px
        margin: 0 10px

    .expandIcon
      padding: 10px

  .languageItemsContainer
    border-radius: 5px
    box-shadow: 0px 0px 4px $primary--text--colour
    position: absolute
    bottom: 46px
    left: 8px

    .languageItems
      display: flex
      flex-direction: column
      align-items: flex-start

      .item
        color: var(--switcher-dropdown-button-colour)
        background-color: var(--switcher-dropdown-background-colour)
        padding: 6px
        cursor: pointer
        width: 100%
        display: flex
        align-items: center
        font-size: 16px

        &:hover
          color: var(--switcher-dropdown-active-text-colour)
          background-color: var(--switcher-dropdown-active-background-colour)
          cursor: pointer
        &.active
          color: var(--switcher-dropdown-active-text-colour)
          background-color: var(--switcher-dropdown-active-background-colour)

      .item:first-child
        border-top-right-radius: 5px
        border-top-left-radius: 5px

      .item:last-child
        border-bottom-right-radius: 5px
        border-bottom-left-radius: 5px

      img
        width: 24px
        margin: 0px 6px

  .rightAligned
    left: -5px

