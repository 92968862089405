@import '../_all_vars'

:root
  --form-input-primary-text-colour: #{$recall-text-menu}
  --form-input-aurecon-error-800: #e0452a
div:global(.pageThemeDark)
  --form-input-primary-text-colour: #{$recall-primary-white}
  --form-input-aurecon-error-800: #e0452a

.joditHolder
  flex: 1 1
  color: var(--form-input-primary-text-colour)
  .joditCharCount
    width: 100%
    text-align: right
    font-size: 12px
  div:global(.jodit-react-container)
    display: flex
    flex: 1 1
    flex-direction: column

    div:global(.jodit-container:not(.jodit_inline))
      background-color: transparent
      div:global(.jodit-workplace)
        background-color: transparent
      div:global(.jodit-status-bar)
        display: none
  
    div:global(.jodit-wysiwyg)
      word-break: break-all
  
  label
    div:global(.input-error)
      text-align: end
