@import './_all_vars.sass'

:root
  --chat-image-border-colour: #{$recall-main-dark}

div:global(.pageThemeDark)
  --chat-image-border-colour: #{$recall-green-200-darkmode}

.wrapper
  display: flex
  flex-wrap: wrap
  margin-bottom: 16px
  margin-left: 72px
  gap: 24px
