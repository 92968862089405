@import '../_all_vars'

:root
    --bamboo-confirm-modal-primary-color: #{$recall-heading}

div:global(.pageThemeDark)
    --bamboo-confirm-modal-primary-color: #{$recall-primary-white}

.confirmModalContainer
    .titleHolder
        align-self: flex-start

        h2
            align-self: baseline
            font-size: 32px
            width: 100%
            color: var(--bamboo-confirm-modal-primary-color)
    
    .messageHolder
        font-size: 14px

