@import './_all_vars.sass'

:root
  --terms-Conditions-title-link-colour: #{$recall-main-dark}
  
div:global(.pageThemeDark)
  --terms-Conditions-title-link-colour: #{$recall-green-500-darkmode}


.modalDivider
  position: relative
  border-bottom: 1px solid gray
  margin: 16px -24px 24px

.staticContent
  display: flex
  flex: 1
  flex-direction: column
  overflow: auto
  margin: 16px
  padding: 16px
  text-align: left

  h1
    font-family: inherit
    font-size: 30px
    font-weight: 400
    color: var(--terms-Conditions-title-link-colour, $recall-main-dark)
    text-align: center
    
    sup
      font-size:6px
  p
    font-size: 16px

    &.note
      color: $recall-text-lighter
      font-style: italic

  a
    font-size: 16px
    color: var(--terms-Conditions-title-link-colour, $recall-main-dark)

  .backToTopBtn
    text-align: right

  .list
    font-size: 16px
    padding-inline-start: inherit
    counter-reset: item

    > li
      margin-top: 3em

      > span
        font-weight: bold

  .listSubLevel1
    font-size: 16px
    font-weight: 300
    padding-left: 0
    list-style-type: none

    li > span
      padding-right: 6px

    ul > li
      list-style-type: circle
      font-weight: 400


  .listSubLevel2
    padding-inline-start: 35px

    > li
      list-style-type: none !important

  ul
    margin-bottom: 2em

    li
      line-height: 1.5em
      margin-bottom: 1em

  .tableList
    li
      font-family: Arial, Helvetica, sans-serif
      font-size: 14px

.center
  justify-content: center