@import './_all_vars.sass'

:root
  --fullScreenSwitch-background-colour: #{$recall-primary-white}
  --fullScreenSwitch-text-colour: #{$recall-main-dark}
  --fullScreenSwitch-background-colour-hover: #{$recall-menu-active}
  --fullScreenSwitch-text-colour-hover: #{$recall-main-dark}

div:global(.pageThemeDark)
  --fullScreenSwitch-background-colour: #{$recall-primary-black-darkmode}
  --fullScreenSwitch-text-colour: #{$recall-primary-text-colour-darkmode}
  --fullScreenSwitch-background-colour-hover: #{$recall-primary-black-darkmode}
  --fullScreenSwitch-text-colour-hover: #{$recall-green-200-darkmode}

.switch
  p
    margin: 0

  .headerButton
    border: none !important
    background-color: var(--fullScreenSwitch-background-colour) !important
    color: var(--fullScreenSwitch-text-colour) !important

    &:hover:enabled:not(.is-loading),
    &:focus:enabled:not(.is-loading)
      background-color: var(--fullScreenSwitch-background-colour-hover) !important
      border: none !important
      color: var(--fullScreenSwitch-text-colour-hover) !important
    &:global(.button.is-icon.is-medium)
      width: 38px
      height: 38px
