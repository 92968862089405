@import './_all_vars.sass'
@import '../../node_modules/@aurecon-creative-technologies/styleguide/src/scss/_component-styles.scss'
@import './style-overrides.sass'

.modal-theme-light
  --modal-link-text-colour: #{$recall-main-dark}
.modal-theme-dark
  --modal-link-text-colour: #{$recall-green-500-darkmode}


*
  box-sizing: border-box
  font-family: inherit

span
  font-family: inherit

.home-background
  position: relative

  figcaption
    position: absolute
    top: 90px
    background-color: #263238d9
    color: white
    max-width: 50%
    margin-left: 100px
    padding: 20px
    font-style: normal
    font-weight: 300 !important
    font-size: 50px
    line-height: 60px

.figurelink
  font-weight: bold
  font-style: italic
  cursor: pointer

.ck-button__thumbnail
  img.ck-thumbnail
    width: 128px
    height: 128px
    margin-right: 12px

// Modal Overrides
.modal-wrapper
  .modal
    h1
      margin-top: 0px

    .modal-actions button
      margin-right: 20px

  a
    color: var(--modal-link-text-colour)

  &.is-cookie-modal,
  &.is-terms-modal
    .modal-overlay
      z-index: 300

    .modal
      position: absolute
      bottom: 200px
      width: 800px

      p, a
        font-size: 14px
        color: $primary--text--colour

  &.is-cookie-modal
    &.no-overlay
      left: calc(50vw - 410px)
      top: auto
      bottom: 80px
      z-index: 1000
      width: 820px
      height: 200px

      .modal
        position: relative
        bottom: auto
        max-width: 820px

    .modal
      bottom: 100px

  &.is-bottom
    pointer-events: none
    position: sticky
    overflow: initial
    bottom: 0px

    .modal-overlay
      background-color: transparent
      display: none

    .modal
      position: static
      pointer-events: auto
      bottom: 0px
      left: 0px
      right: 0px
      width: 100%
      max-width: 100%

  &.is-inside-parent
    position: absolute
    top: 0
    z-index: 1014

    .modal-overlay
      position: absolute

    .modal
      position: absolute
      pointer-events: auto

  &.transparent
    .modal
      background-color: transparent
      box-shadow: none

.swagger-ui .info
  border: none

.toast-message
  word-wrap: break-word
