@import './_all_vars.sass'

:root
  --bamboo-property-text-color: #{$primary--text--colour}
  --bamboo-button-background: #{$recall-bamboo-button-background}
  --bamboo-property-no-data-text-color: #{$recall-bamboo-property-no-data-text-color}

div:global(.pageThemeDark)
  --bamboo-property-text-color: #{$secondary--text--colour}
  --bamboo-button-background: #{$recall-bamboo-button-background-darkmode}
  --bamboo-property-no-data-text-color: #{$recall-bamboo-property-no-data-text-color-darkmode}

.contentWrapper
  h2
    font-size: 24px
    text-align: left
    color: var(--bamboo-property-text-color)
  
  input, textarea
    background-color: transparent !important

  .propertyDescription
    color: var(--bamboo-property-no-data-text-color)
    font-weight: 400

  .typeWrapper
    display: flex
    width: 100%

    .checkboxWrapper
      width: 25%
      font-size: 14px
      padding-left: 20px

      label
        margin-top: 18px
      
      b
        font-weight: 500
        
    .dropdownWrapper
      width: 75%
      .type
        margin-top: 10px

  .infoRow
    display: flex
    flex-wrap: nowrap
    align-items: center
    font-size: 14px

    i
      color: var(--bamboo-button-background)

    span
      margin-left: 8px
      color: var(--bamboo-button-background)
      font-size: 14px

  .limitCharacters
    margin-top: 8px