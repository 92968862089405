@import './_all_vars.sass'

:root
  --page-background-colour: #{$recall-primary-white}
  --page-primary-heading-colour: #{$recall-heading}
  --page-primary-text-colour: #{$primary--text--colour}
  --page-banner-message-text-colour: #{$recall-banner-text-color}
  --page-banner-message-background-colour: #{$recall-banner-message-background}

div:global(.pageThemeDark)
  --page-background-colour: #{$recall-primary-black-darkmode}
  --page-primary-heading-colour: #{$recall-heading-darkmode}
  --page-primary-text-colour: #{$recall-heading-darkmode}
  --page-banner-message-text-colour: #{$recall-banner-text-color-darkmode}
  --page-banner-message-background-colour: #{$recall-banner-message-background-darkmode}

.container
  width: 100%
  max-width: 100% !important

.page
  font-family: 'Lato'
  display: flex
  flex-direction: column
  width: 100%
  height: 100vh
  background-color: var(--page-background-colour)
  overflow: auto
  color: var(--page-primary-text-colour)
  transition: background-color 250ms linear

  &:global(.pageFontOpenSans)
    font-family: 'Open Sans'

  .bannerContainer
    background: var(--page-banner-message-background-colour)
    color: var(--page-banner-message-text-colour)
    padding: 0 10px
    margin: 10px
    border-radius: 10px
    border: 2px solid yellow
    border-left: 5px solid yellow
    display: flex
    height: fit-content
    position: absolute
    z-index: 1000
    width: 99%

    .message
      position: relative
      width: 100%
      text-align: left
      align-content: center
      top: 0

      a, h1, h2, p
        color: var(--page-banner-message-text-colour)

    .iconHolder
      position: relative

      .icon
        z-index: 1
        cursor: pointer
        color: var(--page-banner-message-text-colour)
        font-size: 20px
        padding-top: 16px


  .pageContents
    display: flex
    flex: 1
    flex-direction: column
    margin-top: 16px
    margin-bottom: 100px

    &.noGrow
      flex: 0

    &.fullscreen
      width: 100%

  h1
    font-family: inherit
    font-size: 40px
    font-weight: 400
    color: var(--page-primary-heading-colour)
    text-align: center

  h2
    font-family: inherit
    font-size: 30px
    font-weight: 400
    color: var(--page-primary-heading-colour)
    text-align: center

  p
    font-family: inherit
    font-weight: 300

  .recallLogo
    margin: 16px 0px
    height: 50px

  .columns
    display: flex
    flex-direction: row
    width: 100%
    height: 100vh

    .layoutContainer
      display: flex
      flex: 1
      flex-direction: column

    .mainPageWrapper
      position: relative
      display: flex
      flex-direction: column
      flex: 1
      padding: 0 16px
      align-items: center
      overflow: auto

@media screen and (max-width: $breakpoint--tablet + 1)
  .page
    .pageContents
      padding-right: 4px

      h1
        font-size: 30px
        margin: 16px 0 8px

      h2
        font-size: 24px
        margin: 8px 0 4px

    .columns
      .mainPageWrapper
        margin: 8px 0 8px 0px
        padding: 0 8px

@media screen and (max-width: $breakpoint--mobile)
  .page
    .pageContents
      width: 100%

@media screen and (min-width: $breakpoint--mobile)
  .page
    .pageContents
      width: 100%

@media screen and (min-width: $breakpoint--tablet)
  .page
    .pageContents
      width: 100%

@media screen and (max-width: $breakpoint--desktop)
  .page
    .bannerContainer
      width: 95%
      
@media screen and (min-width: $breakpoint--desktop)
  .page
    .pageContents
      width: 650px

@media screen and (min-width: $breakpoint--desktop-large)
  .page
    .pageContents
      width: 880px

@media screen and (min-width: $breakpoint--desktop)
  .page
    .pageWideContents
      width: 100%

@media screen and (min-width: 1600px)
  .page
    .pageWideContents
      width: 1270px
