@import './_all_vars.sass'

h1
  font-family: inherit
  font-size: 40px
  font-weight: 400
  color: $recall-heading
  text-align: center

h2
  font-family: inherit
  font-size: 30px
  font-weight: 400
  color: $recall-heading
  text-align: center

.message
  align-self: center

  p
    text-align: center

    i
      color: $recall-status-warning

@media screen and (max-width: $breakpoint--tablet + 1)
  h1
    font-size: 30px
    margin: 8px 0

  h2
    font-size: 24px
    margin: 4px 0

@media screen and (max-width: $breakpoint--mobile)
  .chatTitle
    margin: 36px 0 8px 0 !important