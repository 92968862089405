@import './_all_vars.sass'

:root
  --chat-question-text-colour: #{$recall-main-dark}
  --chat-question-edited-text: #{$primary--text--colour}
  --chat-question-separator: #{$recall-separator}
  --chat-question-icon: #{$primary--text--colour--lighter}

div:global(.pageThemeDark)
  --chat-question-text-colour:#{$recall-green-500-darkmode}
  --chat-question-edited-text: #{$recall-primary-white}
  --chat-question-separator: #{$recall-separator-darkmode}
  --chat-question-icon: #{$recall-green-500-darkmode}


.question
  display: flex
  flex-direction: column
  border-bottom: 1px solid var(--chat-question-separator)

  .questionText
    display: flex
    padding: 16px 0
    margin-top: 16px
    color: var(--chat-question-text-colour)

    .avatar
      flex: 0
      align-self: center
      margin-right: 48px
      align-self: flex-start

    .text
      @include lato-regular
      font-size: 16px
      flex: 1
      text-wrap: pretty
      gap: 12px

      .fileTooltip
        text-overflow: ellipsis
        overflow: hidden

      .editedIcon
        span:global(.tooltip-popup)
          font-size: 14px
          color: var(--chat-question-edited-text)

        i
          line-height: 1
          color: var(--chat-question-icon)
          cursor: pointer

@media screen and (max-width: $breakpoint--tablet + 1)
  .question
    .questionText
      .avatar
        margin-right: 8px
