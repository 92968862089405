@import '../_all_vars'

:root
    --bamboo-dropdown-background: #{$recall-bamboo-dropdown-background}
    --bamboo-dropdown-icon: #{$recall-bamboo-dropdown-icon}
    --bamboo-dropdown-selected-is-open-background: #{$recall-bamboo-dropdown-selected-background}
    --bamboo-dropdown-selected-is-open-text: #{$recall-bamboo-dropdown-selected-text}
    --bamboo-dropdown-selected-is-open-icon: #{$recall-bamboo-dropdown-selected-text}
    --bamboo-dropdown-selected-background: #{$recall-bamboo-dropdown-selected-background}
    --bamboo-dropdown-selected-border: #{$recall-bamboo-dropdown-selected-background-border}
    --bamboo-dropdown-selected-hover-background: #{$recall-bamboo-dropdown-selected-background-hover}
    --bamboo-dropdown-selected-hover-border: #{$recall-bamboo-dropdown-selected-border-hover}
    --bamboo-dropdown-text-color: #{$recall-bamboo-dropdown-text}
    --bamboo-dropdown-options-text-color: #{$recall-bamboo-dropdown-options-text-color}
    --bamboo-dropdown-text-hover-color: #{$recall-bamboo-dropdown-text-hover-color}
    --bamboo-dropdown-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), 0px 0px 8px rgba(0, 0, 0, 0.1)

    --cra-dropdown-selected-hover-border: #{$recall-cra-dropdown-selected-border-hover}
    --cra-dropdown-selected-hover-background: #{$recall-cra-dropdown-selected-border-hover}
    --cra-dropdown-options-text-color: #{$recall-cra-dropdown-options-text-color}

    --recall-dropdown-selected-hover-border: #{$recall-recall-chat}
    --recall-dropdown-selected-hover-background: #{$recall-recall-chat}
    --recall-dropdown-background: #{$recall-chat-dropdown-background}

div:global(.pageThemeDark)
    --bamboo-dropdown-background: #{$recall-bamboo-dropdown-background}
    --bamboo-dropdown-icon: #{$recall-bamboo-dropdown-icon-darkmode}
    --bamboo-dropdown-selected-is-open-background: #{$recall-bamboo-dropdown-background}
    --bamboo-dropdown-selected-is-open-text: #{$recall-bamboo-dropdown-selected-text-darkmode}
    --bamboo-dropdown-selected-is-open-icon: #{$recall-bamboo-dropdown-selected-text-darkmode}
    --bamboo-dropdown-selected-background: #{$recall-bamboo-dropdown-selected-background}
    --bamboo-dropdown-selected-border: #{$recall-bamboo-dropdown-selected-background-border-darkmode}
    --bamboo-dropdown-selected-hover-background: #{$recall-bamboo-dropdown-selected-background-hover-darkmode}
    --bamboo-dropdown-selected-hover-border: #{$recall-bamboo-dropdown-selected-border-hover-darkmode}
    --bamboo-dropdown-text-color: #{$recall-bamboo-dropdown-text-darkmode}
    --bamboo-dropdown-options-text-color: #{$recall-bamboo-dropdown-options-text-color-darkmode}
    --bamboo-dropdown-text-hover-color: #{$recall-bamboo-dropdown-text-hover-color-darkmode}
    --bamboo-dropdown-box-shadow: none

    --cra-dropdown-selected-hover-border: #{$recall-cra-dropdown-selected-border-hover-darkmode}
    --cra-dropdown-selected-hover-background: #{$recall-cra-dropdown-selected-border-hover-darkmode}
    --cra-dropdown-options-text-color: #{$recall-cra-dropdown-options-text-color-darkmode}

    --recall-dropdown-selected-hover-border: #{$recall-chat-dropdown-hover-background-darkmode}
    --recall-dropdown-selected-hover-background: #{$recall-chat-dropdown-hover-background-darkmode}
    --recall-dropdown-background: #{$recall-chat-dropdown-background}

.bamboo
    &:focus
        div:global(.dropdown-selected)
            background: unset !important
            color: unset !important

    div:global(.dropdown-selected)
        background-color: unset !important
        border-radius: 4px
        border: 1px solid var(--bamboo-dropdown-selected-border) !important
        color: var(--bamboo-dropdown-text-color)

        i
            color: var(--bamboo-dropdown-icon) !important

        &:hover,
        &:focus
            background-color: unset !important
            color: var(--bamboo-dropdown-text-color) !important
            border: 1px solid var(--bamboo-dropdown-selected-hover-border) !important
            
    div:global(.dropdown-selected.is-open)
        background-color: var(--bamboo-dropdown-selected-is-open-background) !important
        color: var(--bamboo-dropdown-selected-is-open-text) !important
        i
            color: var(--bamboo-dropdown-selected-is-open-icon) !important

    div:global(.dropdown-options)
        margin-bottom: 5px !important
        border-radius: 4px !important
        border: none !important
        box-shadow: var(--bamboo-dropdown-box-shadow) !important

        div:global(.dropdown-item)
            background-color: var(--bamboo-dropdown-background)
            color: var(--bamboo-dropdown-options-text-color)

            &:hover,
            &:focus
                background-color: var(--bamboo-dropdown-selected-hover-background) !important
                color: var(--bamboo-dropdown-options-text-color) !important

        div:global(.dropdown-item.is-selected)
            background-color: var(--bamboo-dropdown-selected-background) !important
            color: var(--bamboo-dropdown-text-hover-color) !important

.cra
    &:focus
        div:global(.dropdown-selected)
            background: unset !important
            color: unset !important

    div:global(.dropdown-selected)
        background-color: unset !important
        border-radius: 4px
        border: 1px solid var(--bamboo-dropdown-selected-border) !important
        color: var(--bamboo-dropdown-text-color)

        i
            color: var(--cra-dropdown-selected-hover-border) !important

        &:hover,
        &:focus
            background-color: unset !important
            color: var(--bamboo-dropdown-text-color) !important
            border: 1px solid var(--cra-dropdown-selected-hover-border) !important
            
    div:global(.dropdown-selected.is-open)
        background-color: var(--bamboo-dropdown-selected-is-open-background) !important
        color: var(--bamboo-dropdown-selected-is-open-text) !important
        i
            color: var(--bamboo-dropdown-selected-is-open-icon) !important

    div:global(.dropdown-options)
        margin-bottom: 5px !important
        border-radius: 4px !important
        border: none !important
        box-shadow: var(--bamboo-dropdown-box-shadow) !important


        div:global(.dropdown-item)
            background-color: var(--bamboo-dropdown-background)
            color: var(--bamboo-dropdown-options-text-color)

            &:hover,
            &:focus
                background-color: var(--cra-dropdown-selected-hover-background) !important
                color: var(--cra-dropdown-options-text-color) !important

        div:global(.dropdown-item.is-selected)
            background-color: var(--bamboo-dropdown-selected-background) !important
            color: var(--bamboo-dropdown-text-hover-color) !important

.recall
    &:focus
        div:global(.dropdown-selected)
            background: unset !important
            color: unset !important

    div:global(.dropdown-selected)
        background-color: unset !important
        border-radius: 4px
        border: 1px solid var(--bamboo-dropdown-selected-border) !important
        color: var(--bamboo-dropdown-text-color)

        i
            color: var(--recall-dropdown-selected-hover-border) !important

        &:hover,
        &:focus
            background-color: unset !important
            color: var(--bamboo-dropdown-text-color) !important
            border: 1px solid var(--recall-dropdown-selected-hover-border) !important
            
    div:global(.dropdown-selected.is-open)
        background-color: var(--bamboo-dropdown-selected-is-open-background) !important
        color: var(--bamboo-dropdown-selected-is-open-text) !important
        i
            color: var(--bamboo-dropdown-selected-is-open-icon) !important

    div:global(.dropdown-options)
        margin-bottom: 5px !important
        border-radius: 4px !important
        border: none !important
        box-shadow: var(--bamboo-dropdown-box-shadow) !important


        div:global(.dropdown-item)
            background-color: var(--recall-dropdown-background)
            color: var(--bamboo-dropdown-options-text-color)

            &:hover,
            &:focus
                background-color: var(--recall-dropdown-selected-hover-background) !important
                color: var(--cra-dropdown-options-text-color) !important

        div:global(.dropdown-item.is-selected)
            background-color: var(--bamboo-dropdown-selected-background) !important
            color: var(--bamboo-dropdown-text-hover-color) !important
