@import './_all_vars.sass'

.wrapper
  display: flex
  align-items: center
  gap: 12px
  padding: 16px 0

  .fileItem
    display: flex
    flex-direction: row
    align-items: center
    gap: 6px
    word-break: break-all
