@import './_all_vars.sass'

:root
  --dashboard-main-background: #{$recall-code-background}
  --dashboard-message-text-colour: #{$recall-message-text-light}

div:global(.pageThemeDark)
  --dashboard-main-background: #{$recall-menu-background-darkmode}
  --dashboard-message-text-colour: #{$recall-primary-text-colour-darkmode}

.searchFilter
  display: flex
  flex-direction: row
  flex-wrap: wrap
  background-color: var(--dashboard-main-background)
  padding: 20px
  gap: 16px
  border-radius: 40px

  .item
    font-size: 12px
    font-weight: bold
    gap: 12px
    display: flex
    flex-direction: row
    align-items: center
    flex: 0
    &:nth-child(1)
      min-width: 150px
    &:nth-child(2)
      min-width: 200px
    &:nth-child(3)
      min-width: 300px

    .wide
      min-width: 300px

  .download
    flex: 1
    display: flex
    flex-direction: row-reverse

    button:global(.is-primary)
      border-radius: 20px

.message
  text-align: center
  color: var(--dashboard-message-text-colour)

.pagination
  display: flex
  align-self: center
  margin: 1rem 0

@media screen and (max-width: $breakpoint--mobile)
  .searchFilter
      .item:nth-child(3)
        min-width: 250px