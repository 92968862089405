@import './_all_vars.sass'

:root
    --bamboo-entity-description-color: #{$recall-bamboo-entity-modal-description-color}
    --bamboo-entity-background: #{$recall-bamboo-button-background}
    --bamboo-entity-button-color: #{$recall-bamboo-button-color}
    --bamboo-entity-text-color: #{$primary--text--colour}
    --bamboo-entity-cancel-button-background: #{$recall-bamboo-entity-modal-cancel-background}

div:global(.pageThemeDark)
    --bamboo-entity-description-color: #{$recall-bamboo-entity-modal-description-darkmode}
    --bamboo-entity-background: #{$recall-bamboo-button-background-darkmode}
    --bamboo-entity-button-color: #{$recall-bamboo-button-color-darkmode}
    --bamboo-entity-text-color: #{$secondary--text--colour}
    --bamboo-entity-cancel-button-background: #{$recall-bamboo-entity-modal-cancel-background-darkmode}

.contentWrapper
    h2
        font-size: 24px
        text-align: left
        color: var(--bamboo-entity-text-color)

    button
        border-radius: 24px
    
    input, textarea
        background-color: transparent !important
        

    .entityDescription
        color: var(--bamboo-entity-description-color)
        font-weight: 400

    .createButton
        background-color: var(--bamboo-button-background) !important
        border: 1px solid var(--bamboo-button-background) !important

        &:hover,
        &:focus
            background-color: var(--bamboo-button-background) !important
            color: var(--bamboo-button-color) !important
            border: 1px solid var(--bamboo-button-background) !important

    .cancelButton
        background-color: var(--bamboo-entity-cancel-button-background) !important
        border: 1px solid var(--bamboo-button-background) !important
        color: inherit !important

        &:hover,
        &:focus
            background-color: var(--bamboo-entity-cancel-button-background) !important
            color: inherit !important
            border: 1px solid var(--bamboo-button-background) !important

    .form
        .infoRow
            display: flex
            flex-wrap: nowrap
            align-items: center

            i
                color: var(--bamboo-button-background)

            span
                margin-left: 8px
                color: var(--bamboo-button-background)
                font-size: 14px

        .limitCharacters
            margin-top: 8px
