@import './_all_vars.sass'

:root
  --chat-answer-link-text: #{$recall-text-lighter}
  --chat-answer-link-text-hover: #{$recall-primary-black}
  --chat-answer-link-background-hover: #{$recall-chat-button-background-hover}
  --chat-answer-link-border: #{$recall-text-lighter}
  --chat-answer-separator: #{$recall-separator}
  --chat-answer-table: #{$recall-menu-background}
  --chat-answer-anchor-colour: #{$recall-main-darker}


div:global(.pageThemeDark)
  --chat-answer-link-text: #{$recall-primary-text-colour-darkmode}
  --chat-answer-link-text-hover: #{$recall-primary-black}
  --chat-answer-link-background-hover: #{$recall-green-200-darkmode}
  --chat-answer-link-border: #{$recall-primary-text-colour-darkmode}
  --chat-answer-separator: #{$recall-separator-darkmode}
  --chat-answer-table: #{$recall-menu-background-darkmode}
  --chat-answer-anchor-colour: #{$recall-green-500-darkmode}

.answer
  display: flex

  .avatar
    flex: 0
    flex-direction: row
    margin-right: 48px
    margin-top: 14px
    align-self: flex-start

  .content
    width: calc(90% - 8px)

    p, li, a
      @include lato-light
      font-size: 16px
      line-height: 1.5
    a
      color: var(--chat-answer-anchor-colour)
      
    .codeBlock
      position: relative

      .copyCodeBtn
        position: absolute
        top: 0
        right: 0

  table
    border-spacing: 0
    @include lato-light

    th
      background-color: var(--chat-answer-table)
      border: 1px solid var(--chat-answer-separator)
      padding: 8px

    td
      border: 1px solid var(--chat-answer-separator)
      padding: 8px

  .quickLinks
    display: flex
    flex-direction: column
    flex: 1
    gap: 8px
    padding: 16px 0

    .label
      font-size: 14px
      font-weight: 700

    .links
      display: flex
      flex-direction: row
      flex-wrap: wrap
      gap: 8px

      .linkButton
        border-radius: 4px
        color: var(--chat-answer-link-text)
        border-color: var(--chat-answer-link-border)
        background-color: transparent
        max-width: 90%
        overflow: hidden
        text-overflow: ellipsis

        &:hover
          color: var(--chat-answer-link-text-hover) !important
          background-color: var(--chat-answer-link-background-hover) !important
          border: 1px solid  var(--chat-answer-link-background-hover) !important

      .addButton
        color: white !important

      .genButton
        font-size: 16px
        font-weight: unset
        text-decoration: underline

        i:global(.material-icons)
          margin-right: 3px
      
      .capabilityPill
        border-radius: 20px

        .capPillContent
          display: flex
          align-items: center

          .capIcon
            margin-right: 8px
        
          .longText
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
            max-width: 890px
            display: block
          
          .strikeThrough
            text-decoration: line-through

@media screen and (max-width: $breakpoint--tablet + 1)
  .answer
    .avatar
      margin-right: 8px