@import './_all_vars.sass'

.pdfViewerContainer
  display: flex
  flex-direction: column
  align-items: center
  overflow: auto
  position: absolute
  max-width: 100%

.pdfPageContainer
  display: flex
  justify-content: center

.pdfViewerWrapper
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  height: 100%
  overflow-y: auto

.showOnlyAsPreview
  overflow: hidden
