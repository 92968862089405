@import './_all_vars.sass'

.tooltipWrapper

    span:global(.tooltip-popup)
        top: -40px !important
        @include lato-light

    .fileAttachButtonWrapper
        width: 40px
        height: 40px
        position: relative

        .fileAttachButton:global(.is-default)
            border-color: $recall-chat-button-border !important

            &:hover:enabled:not(.is-loading)
                background-color: mix($recall-chat-button-border, white) !important
                border-color: mix($recall-chat-button-border, white) !important

            &:focus:enabled:not(.is-loading)
                background-color: mix($recall-chat-button-background, white) !important

        .chatGpt
            background-color: $recall-gpt-chat
            border-color: $recall-gpt-chat

        .filterCounter
            position: absolute
            top: 0px
            right: -5px
            height: 15px
            width: 15px
            text-align: center
            border-radius: 50%
            font-size: 11px
