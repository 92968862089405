@import './_all_vars.sass'

:root
    --appPill-primary-text-colour: #{$primary--text--colour}
    --appPill-background-color-hover: #{$recall-app-tile-border}

div:global(.pageThemeDark)
    --appPill-primary-text-colour: #{$recall-primary-white}
    --appPill-background-color-hover: #{$recall-menu-background-hover-darkmode}

.pill
  cursor: pointer
  border-radius: 20px
  border: 1px solid #fff
  min-height: 40px
  font-family: inherit
  font-weight: 300
  padding: 8px 16px
  width: fit-content
  color: var(--appPill-primary-text-colour)

  &:hover
    background-color: var(--appPill-background-color-hover)
